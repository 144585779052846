<template>
  <div class="cloudPlat">
    <div class="carousel banner">

      <el-carousel trigger="click" height="6rem" class="slide">
        <el-carousel-item>
          <img :src="slides[0].image" :alt="slides[currentSlideIndex].alt" />
          <div class="bannert" style="color: #27487D;">
            <div>云智物流aPaaS平台</div> 构建企业敏捷的技术底座
          </div>
          <div class="bannert2" :style="{ color: '#27487D' }">
            {{ slides[0].text }}
          </div>
          <div class="img1btn" :style="'color:#27487D;border-color:#27487D'" @click="goPage">
            申请试用
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <img :src="slides[1].image" :alt="slides[currentSlideIndex].alt" />
          <div class="bannert">
            <div>aPaaS模型架构</div> 一套平台服务应用生态
          </div>
          <div class="bannert2" :style="{ color: '#fff' }">
            {{ slides[1].text }}
          </div>
          <div class="img1btn" :style="'color:#fff;border-color:#fff'" @click="goPage">
            申请试用
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <img :src="slides[2].image" :alt="slides[currentSlideIndex].alt" />
          <div class="bannert">
            <div>全系研发工具支持</div> 敏捷响应业务需求交付
          </div>
          <div class="bannert2" :style="{ color: '#fff' }">
            {{ slides[2].text }}
          </div>
          <div class="img1btn" :style="'color:#fff;border-color:#fff'" @click="goPage">
            申请试用
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- <button @click="prevSlide">&lt;</button> -->
      <!-- <div class="slide">
        <div class="bannert" style="color: #27487D;" v-if="currentSlideIndex == 0">
          <div>构建企业敏捷的技术底座</div> 按需灵活部署及拓展
        </div>
        <div class="bannert" v-else-if="currentSlideIndex == 1">
          <div>aPaaS 平台架构，统一底层引擎升级数字化基座 </div>
        </div>
        <div class="bannert" v-else-if="currentSlideIndex == 2">
          <div>构建企业敏捷的技术底座</div> 按需灵活部署及拓展
        </div>

        <div class="bannert2" :style="currentSlideIndex == 0 ? 'color:#27487D' : ''">
          {{ slides[currentSlideIndex].text }}
        </div>
        <div class="img1btn" :style="currentSlideIndex == 0 ? 'color:#27487D;border-color:#27487D' : ''" @click="goPage">
          申请试用
        </div>
        <img :src="slides[currentSlideIndex].image" :alt="slides[currentSlideIndex].alt" />
      </div>
      <div class="round">
        <div class="round-box" @click="currentSlideIndex = 0"></div>
        <div class="round-box" @click="currentSlideIndex = 1"></div>
        <div class="round-box" @click="currentSlideIndex = 2"></div>
      </div> -->
      <!-- 
      <button @click="nextSlide">&gt;</button> -->
    </div>
    <div class="contion-text">
      <div class="logistics-platform">
        <div class="cloudPlat-title">云智物流aPaaS平台</div>
        <div class="cloudPlat-title-text">构建企业敏捷可扩展的技术底座</div>
        <img src="../assets/image/cloudPlat/logistics.png" alt="" />
      </div>
      <div class="Core-values">
        <div class="cloudPlat-title">核心价值</div>
        <div class="cloudPlat-title-text">加速企业物流信息化建设，助力新旧动能转换</div>
        <div class="recommend-t">
          <div class="recommend_01_t" style="margin-left: 0;">
            <div class="recommend_02_t">
              <img src="../assets/image/cloudPlat/row-1.png" alt="" />
              <div>需求适配
              </div>
            </div>
            <div class="recommend_03_t">按行业场景细分 产品适配性高</div>
          </div>
          <div class="recommend_01_t">
            <div class="recommend_02_t">
              <img src="../assets/image/cloudPlat/row-2.png" alt="" />
              <div>开发效率

              </div>
            </div>
            <div class="recommend_03_t">低代码 开发效率提升10倍</div>
          </div>
          <div class="recommend_01_t">
            <div class="recommend_02_t">
              <img src="../assets/image/cloudPlat/row-3.png" alt="" />
              <div>技术门槛
              </div>
            </div>
            <div class="recommend_03_t">专注业务、简单易学、上手简单</div>
          </div>
          <div class="recommend_01_t">
            <div class="recommend_02_t">
              <img src="../assets/image/cloudPlat/row-4.png" alt="" />
              <div>实施成本
              </div>
            </div>
            <div class="recommend_03_t">参数，策略高度可配置 流程可组装</div>
          </div>
          <div class="recommend_01_t" style="margin-left: 0;">
            <div class="recommend_02_t">
              <img src="../assets/image/cloudPlat/row-5.png" alt="" />
              <div>环境部署
              </div>
            </div>
            <div class="recommend_03_t">云、私有 混合多种方式</div>
          </div>
          <div class="recommend_01_t">
            <div class="recommend_02_t">
              <img src="../assets/image/cloudPlat/row-6.png" alt="" />
              <div>监控维护
              </div>
            </div>
            <div class="recommend_03_t">数据，服务、环境，自动化运维
            </div>
          </div>
          <div class="recommend_01_t">
            <div class="recommend_02_t">
              <img src="../assets/image/cloudPlat/row-7.png" alt="" />
              <div>无缝集成
              </div>
            </div>
            <div class="recommend_03_t">服务聚合、接口配置、任务调度</div>
          </div>
          <div class="recommend_01_t">
            <div class="recommend_02_t">
              <img src="../assets/image/cloudPlat/row-8.png" alt="" />
              <div>应用扩展
              </div>
            </div>
            <div class="recommend_03_t">组成化解耦设计，高度可复用</div>
          </div>
        </div>
      </div>
      <div class="tabs-content">
        <div class="cloudPlat-title">主要功能</div>
        <div class="cloudPlat-title-text">为复杂业务场景的开发提供有力支撑</div>
        <div class="tabs-card">
          <!-- 轮播图标题 -->
          <div class="des">
            <div v-for="(   item, index   ) in    floorTwoData.des   "
              :class="{ item: true, f2_active: currentTowIndex === index }" @click="changeItem(index)" :key="index">
              <span>{{ item.title }}</span>
            </div>
          </div>
          <!-- 轮播图内容 -->
          <div class="main">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(   item, index   ) in    floorTwoData.mainContent   " :key="index">
                  <div class="item">
                    <div class="left">
                      <div class="title">{{ item.t1 }}</div>
                      <div class="text">{{ item.iconTit }}</div>
                    </div>
                    <div class="right">
                      <video :src="item.src" style="width: 100%;" controls></video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bom-img">
        <div class="fle1">
          <div class="round-left">
            <div class="margin-icon" style="top: -0.5rem;left: 50%;margin-left: -0.7rem;flex-direction: column;">
              <span style="padding-bottom: 0.2rem;"> 医药生产</span>
              <div style="margin: auto;"></div>
            </div>
            <div class="margin-icon" style="top: 14%;right: 0;margin-right: 0.3rem;">
              <div></div>
              <span> 医药流通</span>
            </div>
            <div class="margin-icon" style="top: 52%;right: 0;margin-right: -0.75rem;">
              <div></div>
              <span style="padding-bottom: 0.2rem;"> 医药连锁</span>
            </div>
            <div class="margin-icon" style="top: 85%;right: 0;margin-right: 0.3rem;">
              <div></div>
              <span style="padding-bottom: 0.2rem;"> 酒水饮料</span>
            </div>
            <div class="margin-icon" style="top: 85%;left: 0;margin-left: 0.3rem;">
              <span style="padding-bottom: 0.2rem;"> 医药三方</span>
              <div></div>
            </div>
            <div class="margin-icon" style="top: 52%;left: 0;margin-left: -0.75rem;">
              <span style="padding-bottom: 0.2rem;"> 食品生鲜</span>
              <div></div>
            </div>
            <div class="margin-icon" style="top: 14%;left: 0;margin-left: 0.3rem;">
              <span> 美妆日化</span>
              <div></div>
            </div>
            <div class="second">
              <div class="svg-module">
                <div class="module-content">
                  <svg-line ref="SvgLine" :rate="0.3333" bg-ring-color="#13387e" ring-color="#00bbff" :stroke-width="10"
                    :part="1" :part-gap="0" :show-gradient="true" :clock-wise="false">
                  </svg-line>
                </div>
              </div>
              <!-- <svg style="width: 5.71rem; height: 5.51rem;">
                <circle class="circle" cx="2.705rem" cy="2.705rem" r="2.605rem" fill="none" stroke-width="16"
                  stroke="#0560D6"></circle>
              </svg> -->
              <div class="cardimg" @click="setProgressVal(0, 0)"
                style="top: -0.695rem;left: 50%;margin-left: -0.495rem;flex-direction: column;">
                应用组件
                <img :src='!checkindex[0] ? checkimgs[0].url : checkimgs[0].bgurl' alt="" />
              </div>
              <div class="cardimg" @click="setProgressVal(300, 1)"
                style="right: -1.195rem; top: 50%;margin-top: -0.79rem;line-height: 0.99rem;">
                <img :src='!checkindex[1] ? checkimgs[1].url : checkimgs[1].bgurl' alt="" />
                聚合服务
              </div>
              <div class="cardimg" @click="setProgressVal(500, 2)"
                style="right: -0.295rem; top: 90%;margin-top: -0.69rem;line-height: 0.99rem;">
                <img :src='!checkindex[2] ? checkimgs[2].url : checkimgs[2].bgurl' alt="" />
                接口管理
              </div>
              <div class="cardimg" @click="setProgressVal(750, 3)"
                style="left: -0.295rem; top: 90%;margin-top: -0.69rem;line-height: 0.99rem;">
                任务调度
                <img :src='!checkindex[3] ? checkimgs[3].url : checkimgs[3].bgurl' alt="" />
              </div>
              <div class="cardimg" @click="setProgressVal(950, 4)"
                style="left: -1.195rem; top: 50%;margin-top: -0.79rem;line-height: 0.99rem;">
                流程编排
                <img :src='!checkindex[4] ? checkimgs[4].url : checkimgs[4].bgurl' alt="" />
              </div>
              <!-- <div class="second_progress"></div> -->
              <div class="three">
                <div class="iconimg" style="left:50%; top: 0%;margin-left: -0.31rem;margin-top: -0.62rem;">
                  <img src="../assets/image/cloudPlat/icon-1.png" alt="" />订单
                </div>
                <div class="iconimg" style="right:0%; top: 30%;margin-right: -0.31rem;margin-top: -0.62rem;">
                  <img src="../assets/image/cloudPlat/icon-2.png" alt="" />调度
                </div>
                <div class="iconimg" style="right:0%; top: 60%;margin-right: -0.41rem;margin-top: -0.31rem;">
                  <img src="../assets/image/cloudPlat/icon-3.png" alt="" />质量
                </div>
                <div class="iconimg" style="right:0%; top: 90%;margin-right: 0.31rem;margin-top: 0.09rem;">
                  <img src="../assets/image/cloudPlat/icon-4.png" alt="" />用户
                </div>
                <div class="iconimg" style="left:0%; top: 90%;margin-left: 0.31rem;margin-top: 0.09rem;">
                  <img src="../assets/image/cloudPlat/icon-5.png" alt="" />库存
                </div>
                <div class="iconimg" style="left:0%; top: 60%;margin-left: -0.41rem;margin-top: -0.31rem;">
                  <img src="../assets/image/cloudPlat/icon-6.png" alt="" />运力
                </div>
                <div class="iconimg" style="left:0%; top: 30%;margin-left: -0.31rem;margin-top: -0.62rem;">
                  <img src="../assets/image/cloudPlat/icon-7.png" alt="" />计费
                </div>
                <div class="centenimg">
                  <img src="../assets/image/cloudPlat/centen.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fle2">
          <div class="fle-box" :class="bgindex == 1 ? 'blue' : ''" @mouseenter='bgindex = 1; setProgressVal(0, 0)'
            @mouseleave="bgindex = 0">
            <div class="row-1"> <img :src='bgindex == 1 ? fleimgs[0].url : fleimgs[0].bgurl' />
              应用组件
            </div>
            <div class="row-2">内置医药、食品、酒水、日化等行业的采购、生产、流通的批发、电商、连锁业态的订单、仓储、运输、计费、质量、管理的应用，以及基于场景功能700余个功能组件。
            </div>
          </div>
          <div class="fle-box" :class="bgindex == 2 ? 'blue' : ''" @mouseenter='bgindex = 2; setProgressVal(300, 1)'
            @mouseleave="bgindex = 0">
            <div class="row-1"> <img :src='bgindex == 2 ? fleimgs[1].url : fleimgs[1].bgurl' /> 聚合服务</div>
            <div class="row-2">聚合服务平台配置化管理服务接入与开放
            </div>
          </div>
          <div class="fle-box" :class="bgindex == 3 ? 'blue' : ''" @mouseenter='bgindex = 3; setProgressVal(500, 2)'
            @mouseleave="bgindex = 0">
            <div class="row-1"> <img :src='bgindex == 3 ? fleimgs[2].url : fleimgs[2].bgurl' /> 接口管理</div>
            <div class="row-2">定时与消息驱动的服务与流程配置，执行器
            </div>
          </div>
          <div class="fle-box" :class="bgindex == 4 ? 'blue' : ''" @mouseenter='bgindex = 4; setProgressVal(750, 3)'
            @mouseleave="bgindex = 0">
            <div class="row-1"> <img :src='bgindex == 4 ? fleimgs[3].url : fleimgs[3].bgurl' /> 任务调度</div>
            <div class="row-2">接口快速配置化30+类型的支持，零代码实现数据交互
            </div>
          </div>
          <div class="fle-box" :class="bgindex == 5 ? 'blue' : ''" @mouseenter='bgindex = 5; setProgressVal(950, 4)'
            @mouseleave="bgindex = 0">
            <div class="row-1"> <img :src='bgindex == 5 ? fleimgs[4].url : fleimgs[4].bgurl' /> 流程编排</div>
            <div class="row-2">工作流与服务流程灵活编排适配业务场景无限扩展
            </div>
          </div>
        </div>
      </div>
      <div class="bom-box">
        <div class="title">服务案例及客户</div>
        <div class="bom">
          <div style="margin-left: 0;"><img src="../assets/image/cloudPlat/logo-1.png" /></div>
          <div><img src="../assets/image/cloudPlat/logo-2.png" /></div>
          <div><img src="../assets/image/cloudPlat/logo-3.png" /></div>
          <div><img src="../assets/image/cloudPlat/logo-4.png" /></div>
          <div><img src="../assets/image/cloudPlat/logo-5.png" /></div>
          <div><img src="../assets/image/cloudPlat/logo-6.png" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SvgLine from "@/components/mobile/mobile-cloud-platform/svgline.vue"

export default {
  components: {
    SvgLine,
  },
  data () {
    return {
      checkimgs: [{
        url: require('@/assets/image/cloudPlat/check-1-1.png'),
        bgurl: require("@/assets/image/cloudPlat/check-1.png")
      }, {
        url: require('@/assets/image/cloudPlat/check-2-1.png'),
        bgurl: require("@/assets/image/cloudPlat/check-2.png")
      }, {
        url: require('@/assets/image/cloudPlat/check-3-1.png'),
        bgurl: require("@/assets/image/cloudPlat/check-3.png")
      }, {
        url: require('@/assets/image/cloudPlat/check-4-1.png'),
        bgurl: require("@/assets/image/cloudPlat/check-4.png")
      }, {
        url: require('@/assets/image/cloudPlat/check-5-1.png'),
        bgurl: require("@/assets/image/cloudPlat/check-5.png")
      }],
      fleimgs: [{
        url: require('@/assets/image/cloudPlat/flex-1-1.png'),
        bgurl: require("@/assets/image/cloudPlat/flex-1.png")
      }, {
        url: require('@/assets/image/cloudPlat/flex-2-1.png'),
        bgurl: require("@/assets/image/cloudPlat/flex-2.png")
      }, {
        url: require('@/assets/image/cloudPlat/flex-3-1.png'),
        bgurl: require("@/assets/image/cloudPlat/flex-3.png")
      }, {
        url: require('@/assets/image/cloudPlat/flex-4-1.png'),
        bgurl: require("@/assets/image/cloudPlat/flex-4.png")
      }, {
        url: require('@/assets/image/cloudPlat/flex-5-1.png'),
        bgurl: require("@/assets/image/cloudPlat/flex-5.png")
      }],
      bgindex: 0,
      checkindex: [false, false, false, false, false],
      myGradient: {
        // 同一个页面中id务必唯一
        id: "svg-linear-gradient001",
        x1: "100%",
        y1: "100%",
        x2: "0%",
        y2: "0%",
        colorStops: [
          {
            offset: "0%",
            color: "#0ae787",
          },
          {
            offset: "100%",
            color: "#fe653c",
          },
        ],
      },
      floorTwoData: {
        des: [{ title: "数据建模" }, { title: "页面开发" }, { title: "服务开发" }, { title: "打印开发" }],
        mainContent: [
          {
            src: require("@/assets/video/cloudPlat/list1.mp4"),
            t1: "数据建模",
            icon: require("/public/static/image/home_new/f2_icon_1.png"),
            iconTit: 'Web SQL工具，多类型数据库支持，快速数据模型构建',
            t2: '在理解客户战略的基础上，应用数据分析模型，进行结构、模式、流程、资源一体化统筹规划设计，提供“规划、软件、设备、运营”的一站式集成交付服务，打造高柔性、高效率、高扩展、高吞吐的仓储作业解决方案。',
            t2List: ['一体化统筹规划设计', '一站式集成交付服务', '高柔性、高效率、高扩展、高吞吐'],
            goMore: "/#/medicineChain",
          },
          {
            src: require("@/assets/video/cloudPlat/list2.mp4"),
            t1: "页面开发",
            iconTit: '丰富的组件、可视化设计、低代码开发，一次开发，多端应用。',
            icon: require("/public/static/image/home_new/f2_icon_1.png"),
            t2: '在理解客户战略的基础上，应用数据分析模型，进行结构、模式、流程、资源一体化统筹规划设计，提供“规划、软件、设备、运营”的一站式集成交付服务，打造高柔性、高效率、高扩展、高吞吐的仓储作业解决方案。',
            t2List: ['一体化统筹规划设计', '一站式集成交付服务', '高柔性、高效率、高扩展、高吞吐'],
            goMore: "/#/medicineChain",
          },
          {
            src: require("@/assets/video/cloudPlat/list3.mp4"),
            t1: "服务开发",
            iconTit: '在线编码与调试智能提示，事务支持。从容面对复杂逻辑。',
            icon: require("/public/static/image/home_new/f2_icon_1.png"),
            t2: '在理解客户战略的基础上，应用数据分析模型，进行结构、模式、流程、资源一体化统筹规划设计，提供“规划、软件、设备、运营”的一站式集成交付服务，打造高柔性、高效率、高扩展、高吞吐的仓储作业解决方案。',
            t2List: ['一体化统筹规划设计', '一站式集成交付服务', '高柔性、高效率、高扩展、高吞吐'],
            goMore: "/#/medicineChain",
          },
          {
            src: require("@/assets/video/cloudPlat/list4.mp4"),
            t1: "打印开发",
            iconTit: '在线数据方案设计，所见即所得打印方案设计与配置。',
            icon: require("/public/static/image/home_new/f2_icon_1.png"),
            t2: '在理解客户战略的基础上，应用数据分析模型，进行结构、模式、流程、资源一体化统筹规划设计，提供“规划、软件、设备、运营”的一站式集成交付服务，打造高柔性、高效率、高扩展、高吞吐的仓储作业解决方案。',
            t2List: ['一体化统筹规划设计', '一站式集成交付服务', '高柔性、高效率、高扩展、高吞吐'],
            goMore: "/#/medicineChain",
          },
        ],
      },
      swiperActiveIndex: 3,
      currentTowIndex: 0,
      slides: [
        { image: require("@/assets/image/cloudPlat/Group2.png"), alt: 'Image 1', text: '一体化集成技术支撑，赋能自主研发扩展能力' },
        { image: require("@/assets/image/cloudPlat/Group4.png"), alt: 'Image 2', text: '打造新一代技术架构的企业技术中台、业务中台及应用平台' },
        { image: require("@/assets/image/cloudPlat/Group3.png"), alt: 'Image 3', text: '简化开发流程，降低人员能力要求，提升开发效率' },
      ],
      progressLen: 660,
      currentSlideIndex: 0,
    }
  },
  computed: {
    currentSlide () {
      return this.slides[this.currentSlideIndex]
    },
  },
  methods: {
    goPage () {
      this.$router.push('/applicationUse')
    },
    setProgressVal (val, index) {
      for (let i = 0; i < index + 1; i++) {
        this.checkindex[i] = true
      }
      this.checkindex.forEach((item, i) => {
        if (i > index) {
          this.checkindex[i] = false
        }
      })
      // this.checkindex = index
      this.$refs.SvgLine.setProgressVal(val)
      this.$forceUpdate()
    },
    changeItemcircle () {
      this.setPercent(20)
    },
    setPercent (num) {
      if (num > 100) return
      this.circleDom.style['stroke-dashoffset'] = this.progressLen - (this.progressLen / 100) * num
    },
    changeTwoIndex (index) {
      this.currentTowIndex = index
    },
    //轮播图
    initSwiper () {
      this.swiper = new Swiper(".swiper-container", {
        loop: true,
        preventClicks: false,
        // autoplay: 2000,
        loopedSlides: 3,
        spaceBetween: 0,
        centeredSlides: true,
        // slidesPerView: "auto",
        pagination: ".swiper-pagination",
        onSlideChangeEnd: (swiper) => {
          this.swiperActiveIndex = swiper.activeIndex
          const len = this.floorTwoData.mainContent.length
          if (swiper.activeIndex > len + len / 2) {
            this.currentTowIndex = swiper.activeIndex - len - len + 1
          } else {
            this.currentTowIndex = swiper.activeIndex - len + 1
          }
        },
      })
    },
    //title点击切换
    changeItem (index) {
      this.currentTowIndex = index
      switch (index) {
        case 0:
          this.swiper.slideTo(3)
          break
        case 1:
          this.swiper.slideTo(4)
          break
        case 2:
          this.swiper.slideTo(5)
          break
        case 3:
          this.swiper.slideTo(6)
          break
        case 4:
          this.swiper.slideTo(1)
          break
        case 5:
          this.swiper.slideTo(8)
          break
        default:
          break
      }
    },
    prevSlide () {
      this.currentSlideIndex = (this.currentSlideIndex - 1 + this.slides.length) % this.slides.length
    },
    nextSlide () {
      this.currentSlideIndex = (this.currentSlideIndex + 1) % this.slides.length
    },
    startAutoPlay () {
      // Add logic to automatically switch to the next slide at a regular interval
    },
    stopAutoPlay () {
      // Add logic to stop the auto-playing of slides
    },
  },
  mounted () {
    this.circleDom = document.getElementsByClassName('circle')[0]
    // this.setPercent(0)
    let i = 0
    // setInterval(() => {
    //   i += Math.floor(Math.random() * 5);
    //   if (i >= 100) i = 100;
    //   this.setPercent(i);
    // }, 250)
    this.startAutoPlay()
    this.initSwiper()
  },
  beforeDestroy () {
    this.stopAutoPlay()
  },
}
</script>

<style lang="scss">
.cloudPlat {
  .el-carousel {
    height: 100%;
    position: relative;
  }

  .el-carousel__container {
    height: 100%;
  }

  .el-carousel__indicators {

    position: absolute;
    bottom: 0.6rem;

    .el-carousel__button {
      width: .08rem;
      height: .08rem;
      background: rgba(31, 119, 248, 0.4);
      border-radius: 50%;
      opacity: 1;
      margin-right: .2rem;
    }

    .is-active {
      .el-carousel__button {
        background: #1F77F8;
      }
    }
  }

  .el-carousel__indicator {
    padding: 0;
  }
}
</style>
<style lang="scss" scoped>
.cloudPlat {
  .banner {
    height: 6rem;
  }

  .carousel {
    position: relative;
    width: 100%;


    .slide {
      /* display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      position: relative; */
      color: #fff;

      .bannert {
        position: absolute;
        top: 1.8rem;
        left: 1.95rem;
        width: 5.28rem;
        height: 1.34rem;
        font-size: 0.48rem;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 0.67rem;
      }

      .bannert2 {
        position: absolute;
        top: 3.33rem;
        left: 1.95rem;
        width: 5.28rem;
        height: 0.33rem;
        font-size: 0.24rem;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.33rem;
      }

      .img1btn {
        position: absolute;
        width: 1.71rem;
        height: 0.54rem;
        border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
        opacity: 1;
        border: 0.01rem solid #FFFFFF;
        font-size: 0.24rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        line-height: 0.54rem;
        top: 4.2rem;
        left: 1.95rem;
        cursor: pointer;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        min-width: 100%;
        min-height: 100%;
      }
    }

    .round {
      position: absolute;
      top: 5.51rem;
      left: 9.32rem;
      width: 0.76rem;
      height: 0.2rem;
      display: flex;

      .round-box {
        cursor: pointer;
        align-items: center;
        width: 0.08rem;
        height: 0.08rem;
        background: rgba(31, 119, 248, 0.4);
        opacity: 1;
        margin-left: 0.16rem;
        border-radius: 50%;
      }

      .round-box:hover {
        width: 0.08rem;
        height: 0.08rem;
        background: #1F77F8;
        /* border-radius: 0rem 0rem 0rem 0rem; */
        opacity: 1;
      }
    }

  }

  .contion-text {


    .logistics-platform {
      padding: 0 2.6rem;

      img {
        width: 14rem;
        height: 6.26rem;
      }
    }

    .Core-values {
      padding: 0 2.6rem;

      .recommend-t {
        width: 14rem;
        height: 2.32rem;
        display: flex;
        flex-wrap: wrap;

        .recommend_01_t {
          width: 3.27rem;
          // width: 25%;
          display: flex;
          flex-direction: column;
          margin-left: 0.28rem;
          height: 1.06rem;
          background: rgba(255, 255, 255, 0.65);
          box-shadow: 0rem 0.04rem 0.69rem -0.23rem #CEE5F5;
          border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
          opacity: 1;
          border: 0.01rem solid;
          border-image: linear-gradient(138deg, rgba(241, 241, 241, 1), rgba(201, 226, 255, 0.76)) 0.01 0.01;

          .recommend_02_t {
            height: 0.59rem;
            display: flex;

            img {
              margin-left: 0.62rem;
              margin-top: 0.19rem;
              width: 0.35rem;
              height: 0.35rem;
            }

            div {
              padding-top: 0.23rem;
              flex: 1;
              font-size: 0.2rem;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #0A121F;
              line-height: 0.28rem;
              align-items: center;

            }
          }

          .recommend_03_t {
            /* text-align: center; */
            margin-left: 0.62rem;
            height: 0.28rem;
            font-size: 0.15rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #565E66;
            line-height: 0.28rem;
          }
        }
      }
    }

    .tabs-content {
      .tabs-card {
        /* box-shadow: 0rem 0.02rem 0.1rem 0rem rgba(197, 221, 255, 0.5); */

        .des {
          margin-left: 10%;
          width: 80%;
          position: relative;
          z-index: 2;
          display: flex;
          padding-top: 0.8rem;
          border-bottom: 0.02rem solid #E3EDF9;

          .item {
            width: 25%;
            text-align: center;
            height: 104%;
            font-size: .24rem;
            font-weight: bold;
            height: 0.6rem;
            color: #0A121F;
            cursor: pointer;
          }
        }

        .main {
          .item {
            position: relative;
            width: 100%;
            height: 6.59rem;
            background: url('~@/assets/image/cloudPlat/tabs-bg.png') no-repeat;

            .left {
              height: 3.5rem;
              margin-left: 2.7rem;
              padding-top: 2.85rem;

              .title {


                height: 0.36rem;
                font-size: 0.36rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #0A121F;
                line-height: 0.36rem;
              }

              .text {
                margin-top: 0.4rem;
                height: 0.28rem;
                font-size: 0.2rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #565E66;
                line-height: 0.28rem;

              }
            }

            .right {
              position: absolute;
              width: 7.5rem;
              left: 8.76rem;
              bottom: 1.03rem;
              height: 4.53rem;
            }
          }

          .common_pagination {
            display: inline-flex;
            margin-left: 8.83rem;
            margin-top: 0.3rem;

            span {
              width: 0.14rem;
              height: 0.04rem;
              background: #ffffff;
              opacity: 0.5;
            }

            span:nth-of-type(n + 2) {
              margin-left: 0.12rem;
            }
          }

          .prev,
          .next {
            position: absolute;
            top: 5.57rem;
            z-index: 3;
            width: 0.62rem;
            height: 0.62rem;
            background: #ffffff;
            box-shadow: 0rem 0rem 0.24rem 0rem rgba(186, 186, 186, 0.75);
            border-radius: 50%;
            cursor: pointer;
            text-align: center;
            line-height: 0.62rem;

            .icon-zuo,
            .icon-you {
              font-size: 0.24rem;
              color: gray;
            }
          }

          .prev {
            left: 1.9rem;
          }

          .next {
            right: 1.9rem;
          }
        }


      }
    }

    .bom-box {
      background: url('~@/assets/image/cloudPlat/bombg.png') no-repeat;
      height: 2.96rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      background-size: cover;

      .title {
        width: 100%;
        height: 0.36rem;
        margin-top: 0.7rem;
        font-size: 0.4rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #0A121F;
        line-height: 0.36rem;
      }

      .bom {
        flex: 1;
        padding: 0 2.6rem;
        display: flex;
        margin-top: 0.53rem;

        div {
          // flex: 1;
          justify-content: center;
          margin-left: 0.71rem;
          width: 2.35rem;
          height: 0.8rem;
          background: #FFFFFF;
          box-shadow: 0.02rem 0.02rem 0.08rem 0rem rgba(197, 221, 255, 0.5);
          border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
          opacity: 1;
          padding: 0.05rem 0.04rem 0.05rem 0.03rem;

          img {
            /* height: 100%; */
            width: 100%;
            margin-top: .1rem;
          }
        }
      }
    }
  }

  .bom-img {
    padding: 0 2.6rem;
    display: flex;
    height: 8.9rem;

    .fle1 {
      flex: 1;
    }

    .fle2 {
      width: 5.22rem;
      display: flex;
      flex-direction: column;

      .fle-box {
        height: 1.26rem;
        margin-top: 0.2rem;
        display: flex;
        flex-direction: column;

        .row-1 {
          display: flex;
          align-items: center;

          img {
            width: 0.35rem;
            height: 0.35rem;
          }
        }

        .row-2 {
          flex: 1;
          display: flex;
          align-items: center;
        }
      }

      // .fle-box:hover {
      //   padding: 0.23rem 0.27rem 0.29rem 0.34rem;
      //   height: 1.76rem;
      //   background: linear-gradient(88deg, #166BD8 0%, rgba(12, 100, 215, 0.42) 58%, rgba(5, 96, 214, 0) 100%, rgba(90, 107, 255, 0) 100%);
      //   box-shadow: 0.04rem 0.04rem 0.13rem 0.03rem rgba(9, 59, 118, 0.15);
      //   border-radius: 0.06rem 0.06rem 0.06rem 0.06rem;
      //   opacity: 1;
      //   font-size: 0.15rem;
      //   font-family: PingFang SC-Regular, PingFang SC;
      //   font-weight: 400;
      //   color: #FFFFFF;
      //   line-height: 0.28rem;
      // }

      .blue {
        padding: 0.23rem 0.27rem 0.29rem 0.34rem;
        height: 1.76rem;
        background: linear-gradient(79deg, #0560D6 0%, #699AFF 100%);
        /* background: linear-gradient(88deg, #166BD8 0%, rgba(12, 100, 215, 0.42) 58%, rgba(5, 96, 214, 0) 100%, rgba(90, 107, 255, 0) 100%); */
        /* box-shadow: 0.04rem 0.04rem 0.13rem 0.03rem rgba(9, 59, 118, 0.15); */
        border-radius: 0.06rem 0.06rem 0.06rem 0.06rem;
        opacity: 1;
        font-size: 0.15rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.28rem;
      }
    }
  }
}

.cloudPlat-title {
  margin-top: 0.7rem;
  text-align: center;
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0A121F;
  line-height: 0.47rem;
}

.cloudPlat-title-text {
  margin-top: 0.12rem;
  margin-bottom: .2rem;
  text-align: center;
  height: 0.24rem;
  font-size: 0.16rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #565E66;
  line-height: 0.24rem;
}

.f2_active {
  border-bottom: 0.04rem solid #1562ec;
}

.round-left {
  margin-top: 0.53rem;
  width: 7.21rem;
  height: 7.21rem;
  opacity: 1;
  border: 0.01rem solid #BCBCBC;
  border-radius: 50%;
  position: relative;

  .margin-icon {
    position: absolute;
    line-height: 0.21rem;
    font-size: 0.15rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #565E66;
    display: flex;

    div {
      width: 0.14rem;
      height: 0.14rem;
      background: #6D8FAE;

      opacity: 1;
      border-radius: 50%;
    }
  }

  .second {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2.75rem;
    margin-left: -2.75rem;
    width: 5.41rem;
    height: 5.41rem;
    border-radius: 50%;
    opacity: 1;
    border: 0.01rem solid #BCBCBC;

    .cardimg {
      position: absolute;
      color: #0560D6;
      font-size: 0.2rem;
      display: flex;

      img {
        width: 0.99rem;
        height: 0.99rem;
      }
    }

    .three {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -1.305rem;
      margin-left: -1.305rem;
      width: 2.61rem;
      height: 2.61rem;
      border-radius: 50%;
      border: 0.01rem solid #BCBCBC;

      .centenimg {
        img {
          width: 2.61rem;
          height: 2.61rem;
        }
      }

      .iconimg {
        position: absolute;
        display: flex;
        flex-direction: column;
        height: 0.21rem;
        font-size: 0.12rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #565E66;
        line-height: 0.21rem;
        text-align: center;

        img {
          width: 0.62rem;
          height: 0.62rem;
        }

      }
    }
  }
}

// .circle {
//   fill: none;
//   stroke: #0560D6;
//   stroke-dasharray: 20%, 30%;
//   stroke-linecap: round;
//   stroke-dashoffset: 20;
//   // animation: circle 3s infinite;
//   transform: rotate(-90deg);
//   transform-origin: center;
//   transform-box: fill-box;
// }

// @keyframes circle {
//   100% {
//     stroke-dashoffset: 0;
//   }
// }


.progress {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.svg-module {
  // width: 23%;
  // height: 46%;
}

.module-content {
  width: 100%;
  height: calc(100% - 30px);
  color: #fff;
  box-sizing: border-box;
}

// .module-title {
//   position: relative;
//   z-index: 1;
//   width: 100%;
//   height: 30px;
//   line-height: 30px;
//   font-size: 16px;
//   text-align: center;
//   color: #fff;
// }

// .module-title::before,
// .module-title::after {
//   content: "";
//   position: absolute;
//   z-index: -1;
//   top: 0;
//   width: 50%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, #061223, #042c4c);
// }

.module-title::before {
  left: 0;
  transform: skew(-45deg);
  transform-origin: left bottom;
}

.module-title::after {
  right: 0;
  transform: skew(45deg);
  transform-origin: right bottom;

}
</style>
